import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import SlotProviderList from "../../snippets/SlotProviderList";
import SlotsFilter from "../../snippets/SlotsFilter";
import GameList from "../../snippets/GameList";
import SlotSearchList from '../../snippets/SlotSearchList';

const spadeFilterOptions = [
  { value: 'all', label: 'All' },
  { value: 'featured', label: 'Featured' },
  { value: 'slots', label: 'Slots' },
  // Add other categories if necessary
];

function Spadegaming() {
  const [currentFilter, setCurrentFilter] = useState('all');
  const [sliderCurrentPage, setSliderCurrentPage] = useState('SPADEGAMING');
  const [bannerCurrentPage, setBannerCurrentPage] = useState('SPADEGAMING');
  const [searchQuery, setSearchQuery] = useState("");

  const handleFilterChange = (filter) => {
    setCurrentFilter(filter);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Spadegaming | SG8.Casino Unique Games</title> 
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta name="description" content="test"/>
        <link href="index.html" rel="canonical"/>
      </Helmet>

      <div className="main-wrapper desktop-view">
        <div className="main-content-area">
          <MainSlider sliderCurrentPage={sliderCurrentPage} bannerCurrentPage={bannerCurrentPage} />
          <SlotProviderList initialProvider="spadegaming" />
          <div className="slot-filter-search-wrapper">
            <div className="provider-logo-slots">
              <img src="/images/slots/small-logo/spadegaming-logo-small.webp" alt="" />
            </div>
            <SlotSearchList searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
            <div className="filter-slots">
              <SlotsFilter filter={currentFilter} setFilter={handleFilterChange} filterOptions={spadeFilterOptions} />
            </div>
          </div>
        </div>
      </div>
      <GameList provider="spade" filter={currentFilter} searchQuery={searchQuery} />

      <div className="main-content-wrapper">
        <div className="text-content-area">
          {/* Content here */}
        </div>
      </div>
    </>
  );
}

export default Spadegaming;
