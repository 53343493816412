import React, { useState, useEffect } from "react";
import LoadingSpinner from "../../snippets/LoadingSpinner";
import BackButton from "../../snippets/BackButton";
import PromoBanner from "../../snippets/PromoBanner";
import { Helmet } from "react-helmet";

export default function ReferralBonus() {
    const title = "Referral Bonus";
    const [loading, setLoading] = useState(true);
    useEffect(() => {
      setTimeout(() => {
        setLoading(false);
      }, 70);
    }, []);
  return <>
      <Helmet>
          <meta charSet="utf-8" />
            <title>Birthday Bonus - Discover and Enjoy Online Casino Bonus at SG8 Philippines</title> <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
      </Helmet>
     <div className="promotionPage">
        <BackButton name={title}/>
    {loading ? (
        <LoadingSpinner />
      ) : (
        <PromoBanner name={title}/>
    )}
        <h1>Referral Bonus up to 10%</h1>
        <p>Php 500 playing credits for eligible players.</p>
        <h2>General Mechanics:</h2>
        <ol>
          <li>Referral commission is based on the referred player’s total losses per week (Monday 06:00 AM – Monday 05:59 AM) and the referrer’s VIP Level (%)</li>
          <li>NGR = GGR – Welcome Bonus/Birthday Bonus/ other Bonuses – Cashback – Promotion.</li>
          <li>Expenses</li>
          <li>NGR (Net Gaming Revenue)</li>
          <li>GGR (Gross Gaming Revenue)</li>
        </ol>
        <h2>Terms and conditions:</h2>
        <ol>
            <li>General SG8.Casino terms and conditions apply.</li>
            <li>SG8.Casino reserves the right to take back any bonuses claimed by players in the event that the terms and conditions of the promotion are found to have been breached by the player, and/or there is reasonable suspicion of foul play.</li>
        </ol>
    </div>
  </>;
}
