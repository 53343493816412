import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import MainSlider from "../../snippets/MainSlider";
import SlotProviderList from "../../snippets/SlotProviderList";
import SlotsFilter from "../../snippets/SlotsFilter";
import GameList from "../../snippets/GameList";
import SlotSearchList from '../../snippets/SlotSearchList';

const bngFilterOptions = [
  { value: 'all', label: 'All' },
  { value: 'featured', label: 'Featured' },
  { value: 'slots', label: 'Slots' },
];

function BNG() {
  const [currentFilter, setCurrentFilter] = useState('all');
  const [sliderCurrentPage, setSliderCurrentPage] = useState('BNG');
  const [bannerCurrentPage, setBannerCurrentPage] = useState('BNG');
  const [searchQuery, setSearchQuery] = useState("");

  const handleFilterChange = (filter) => {
    setCurrentFilter(filter);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>BNG Games | SG8.Casino Unique Games</title> 
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
        <meta name="description" content="BNG Games available at SG8.Casino"/>
        <link href="index.html" rel="canonical"/>
      </Helmet>

      <div className="main-wrapper desktop-view">
        <div className="main-content-area">
          <MainSlider sliderCurrentPage={sliderCurrentPage} bannerCurrentPage={bannerCurrentPage} />
          <SlotProviderList initialProvider="bng" />
          <div className="slot-filter-search-wrapper">
            <div className="provider-logo-slots">
            <img src="/images/slots/small-logo/bng-logo-small.webp" alt="" style= {{width:'130px'}}/>
            </div>
            <SlotSearchList searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
            <div className="filter-slots">
              <SlotsFilter filter={currentFilter} setFilter={handleFilterChange} filterOptions={bngFilterOptions} />
            </div>
          </div>
        </div>
      </div>
      <GameList provider="booongo" filter={currentFilter} searchQuery={searchQuery} />

      <div className="main-content-wrapper">
        <div className="text-content-area">
          {/* Additional content here */}
        </div>
      </div>
    </>
  );
}

export default BNG;
