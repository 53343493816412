import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";

function Vip() {
    const [visibleId, setVisibleId] = useState('benefitsBronze'); // State to track which element should be visible

    // Function to handle click event on deposit links
    const handleVipClick = (id) => {
        setVisibleId(id); // Update the state to show the corresponding element
    };

    // Autoplay functionality
    useEffect(() => {
        const autoplayInterval = setInterval(() => {
            // Logic to switch to the next section
            switch (visibleId) {
                case 'benefitsBronze':
                    setVisibleId('benefitsSilver');
                    break;
                case 'benefitsSilver':
                    setVisibleId('benefitsGold');
                    break;
                case 'benefitsGold':
                    setVisibleId('benefitsPlatinum');
                    break;
                case 'benefitsPlatinum':
                    setVisibleId('benefitsBronze');
                    break;
                default:
                    setVisibleId('benefitsBronze');
            }
        }, 5000); // Change the interval (in milliseconds) as needed

        return () => clearInterval(autoplayInterval); // Cleanup function to clear interval
    }, [visibleId]); // Depend on visibleId to rerun the effect when it changes

    return (
      <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>VIP - SG8 Casino Filipino - PAGCOR-Licensed Slots & Sports Betting</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
     </Helmet>
      
     <div className="main-content-area">
        <div className="page-headlines-container">
          <div className="page-h2-text-style">
            <h1>VIP</h1><span>Get our Best Deals from SG8.Casino</span>
          </div>
        </div>
        <div className="vip-container">
          <div className="vip-slides-continer">
            <div className="vip-selection">
                <div className="vip-selection-item" onClick={() => handleVipClick('benefitsBronze')}>
                    <img className={`${visibleId === 'benefitsBronze' ? 'active' : ''}`} src="/images/bronze.png" alt="bronze level"/>
                </div>
                <div className="vip-selection-item" onClick={() => handleVipClick('benefitsSilver')}>
                    <img className={`${visibleId === 'benefitsSilver' ? 'active' : ''}`} src="/images/silver.png" alt="silver level"/>
                </div>
                <div className="vip-selection-item" onClick={() => handleVipClick('benefitsGold')}>
                    <img className={`${visibleId === 'benefitsGold' ? 'active' : ''}`} src="/images/gold.png" alt="gold level"/>
                </div>
                <div className="vip-selection-item" onClick={() => handleVipClick('benefitsPlatinum')}>
                    <img className={`${visibleId === 'benefitsPlatinum' ? 'active' : ''}`} src="/images/platinum.png" alt="platinum level"/>
                </div>
            </div>
            <div className="benefits-container">
                <div className="benefit-slider">
                {visibleId === 'benefitsBronze' && <div className="benefit-selection-content benefits-bronze fade-in">
                    <h2>Bronze Level</h2>
                    <div className="title-line" style={{float: "left"}}></div>
                    <div className="table-container">
                        <div className="benefits">
                        <h4>Benefits</h4>
                        <table>
                            <tbody>
                            <tr>
                                <th>Payday Bonus</th>
                                <td>Up To 50</td>
                                <td><img src="/images/payday-icon.png" alt="payday bonus"/></td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                        <div className="divider-1"></div>
                        <div className="requirements">
                        <h4>Requirements</h4>
                        <table>
                            <tbody>
                            <tr>
                                <th>Monthly Deposit</th>
                                <td>0</td>
                                <td><img src="/images/vpi-deposit-icon.png" alt="vip deposit bonus"/></td>
                            </tr>
                            <tr>
                                <th>Monthly Turnover</th>
                                <td>0</td>
                                <td><img src="/images/vpi-turnover-icon.png" alt="vip turnover"/></td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    </div>}
                    {visibleId === 'benefitsSilver' && <div className="benefit-selection-content benefits-silver fade-in">
                    <h2>Silver Level</h2>
                    <div className="title-line" style={{float: "left"}}></div>
                    <div className="table-container">
                    <div className="benefits">
                        <h4>Benefits</h4>
                        <table>
                            <tbody>
                            <tr>
                                <th>Payday Bonus</th>
                                <td>Up To 120</td>
                                <td><img src="/images/payday-icon.png" alt="payday bonus"/></td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                        <div className="divider-1"></div>
                        <div className="requirements">
                        <h4>Requirements</h4>
                        <table>
                            <tbody>
                            <tr>
                                <th>Monthly Deposit</th>
                                <td>5,000</td>
                                <td><img src="/images/vpi-deposit-icon.png" alt="vip deposit bonus"/></td>
                            </tr>
                            <tr>
                                <th>Monthly Turnover</th>
                                <td>150,000</td>
                                <td><img src="/images/vpi-turnover-icon.png" alt="vip turnover"/></td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    </div>}
                    {visibleId === 'benefitsGold' && <div className="benefit-selection-content benefits-gold fade-in">
                    <h2>Gold Level</h2>
                    <div className="title-line" style={{float: "left"}}></div>
                    <div className="table-container">
                        <div className="benefits">
                        <h4>Benefits</h4>
                        <table>
                            <tbody>
                            <tr>
                                <th>Payday Bonus</th>
                                <td>Up To 300</td>
                                <td><img src="/images/payday-icon.png" alt="payday bonus"/></td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                        <div className="divider-1"></div>
                        <div className="requirements">
                        <h4>Requirements</h4>
                        <table>
                            <tbody>
                            <tr>
                                <th>Monthly Deposit</th>
                                <td>20,000</td>
                                <td><img src="/images/vpi-deposit-icon.png" alt="vip deposit bonus"/></td>
                            </tr>
                            <tr>
                                <th>Monthly Turnover</th>
                                <td>500,000</td>
                                <td><img src="/images/vpi-turnover-icon.png" alt="vip turnover"/></td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    </div>}
                    {visibleId === 'benefitsPlatinum' && <div className="benefit-selection-content benefits-platinum fade-in">
                    <h2>Platinum Level</h2>
                    <div className="title-line" style={{float: "left"}}></div>
                    <div className="table-container">
                        <div className="benefits">
                        <h4>Benefits</h4>
                        <table>
                            <tbody>
                            <tr>
                                <th>Payday Bonus</th>
                                <td>Up To 600</td>
                                <td><img src="/images/payday-icon.png" alt="payday bonus"/></td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                        <div className="divider-1"></div>
                        <div className="requirements">
                        <h4>Requirements</h4>
                        <table>
                            <tbody>
                            <tr>
                                <th>Monthly Deposit</th>
                                <td>100,000</td>
                                <td><img src="/images/vpi-deposit-icon.png" alt="vip deposit bonus"/></td>
                            </tr>
                            <tr>
                                <th>Monthly Turnover</th>
                                <td>1,500,000</td>
                                <td><img src="/images/vpi-turnover-icon.png" alt="vip turnover"/></td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                    </div>}
                </div>
            </div>
          </div>
          
          <div className="vip-content-area">
            <div className="vip-content">
              <ul className="vip-collapsible">
                {/* <!-- <li>
                  <h3>VIP Tier<div className="plus-minus-toggle collapsed"></div></h3>
                  <div className="collapse-content">
                    <ol>
                      <li>Enjoy more benefits at higher VIP Tiers.</li>
                      <li>VIP Tiers will be updated at 1PM (GMT+8) on the 1st day of every month, based on the data of the preceding month.</li>
                      <li>SG8 reserves the right to amend the terms and conditions of the VIP Tier system without notice in the event there is reasonable suspicion of risk of fraud, and/or any other factors deemed relevant.</li>
                    </ol>
                  </div>
                </li>
                <li>
                  <h3>Weekly Cashback<div className="plus-minus-toggle collapsed"></div></h3>
                  <div className="collapse-content">
                    <ol>
                      <li>The Cashback Bonus is valid from Tuesday 12PM (GMT+8) – Tuesday 12PM (GMT+8) the following week</li>
                      <li>A minimum net loss of PHP 5,000 is required in order to qualify for the Cashback Bonus</li>
                      <li>Players will receive a cashback between 3% - 6% of his/her net loss, dependent on his/her VIP tier</li>
                      <li>There is no maxiumum limit on the cashback to be paid out</li>
                      <li>Cashback will be automatically credited to players' SG8 balance weekly, by Tuesday 3PM (GMT+8)</li>
                      <li>There is a 1x rollover requirement before any withdrawal of the cashback credits can be done</li>
                      <li>SG8 reserves the right to amend the terms and conditions of the Cashback Bonus without notice in the event there is reasonable suspicion of risk of fraud, and/or any other factors deemed relevant</li>
                    </ol>
                  </div>
                </li>
                <li>
                  <h3>Referral Bonus Program<div className="plus-minus-toggle collapsed"></div></h3>
                  <div className="collapse-content">
                    <ol>
                      <li>Referrer must have at least 4 active referred players per week.</li>
                    <li>Referral commission is based on the referred players’ total losses per week (Monday 12PM – Monday 11:59AM) and the referrer’s VIP Level (%)</li>
                    <li>Players' referrals are required to have a minimum accumulated net loss of PHP 10,000 in order for the player to qualify for the Referral Bonus"<br>
                      Example:<br>
                      Referrals' total accumulated losses: PHP 50,000<br>
                      Referral commission received: PHP 50,000 x 33% (Assuming Player's VIP tier is PLATINUM) = PHP 16,500</li>
                    <li>Referral commission will be automically credited to the Player's SG8 Balance every Monday at 3PM (GMT+8)</li>
                    <li>Referrer cannot share the same IP address, bank account name and/or bank account number as referrals. Any instances of such occurences will result in disqualification and ineligibility to receive any form of referral commision</li>
                    <li>Referrer is not allowed to have more than one registered account on SG8</li>
                    <li>There is a 1x rollover requirement before any withdrawal of the Referral Bonus can be done</li>
                    <li>SG8 reserves the right to amend the terms and conditions of the Referral Bonus without notice in the event there is reasonable suspicion of risk of fraud, and/or any other factors deemed relevant</li>
                    </ol>
                  </div>
                </li> */}


                
                <li>
                  <h3>Payday Bonus<div className="plus-minus-toggle collapsed"></div></h3>
                  <div className="collapse-content">
                    <div>
                    <table>
                      <tbody>
                        <tr style={{backgroundColor: "#00000038", border: "solid 1px #3e3e3e14"}}>
                          <th>DEPOSIT AMOUNT</th>
                          <th colSpan="4">BONUS AMOUNT</th>
                        </tr>
                        <tr>
                          <th></th>
                          <th>Bronze</th>
                          <th>Silver</th>
                          <th>Gold</th>
                          <th>Platinum</th>
                        </tr>
                        <tr>
                          <td>500</td>
                          <td>50</td>
                          <td>50</td>
                          <td>50</td>
                          <td>50</td>
                        </tr>
                        <tr>
                          <td>1000</td>
                          <td>50</td>
                          <td>120</td>
                          <td>120</td>
                          <td>120</td>
                        </tr>
                        <tr>
                          <td>2000</td>
                          <td>50</td>
                          <td>120</td>
                          <td>300</td>
                          <td>300</td>
                        </tr>
                        <tr>
                          <td>3000+</td>
                          <td>50</td>
                          <td>120</td>
                          <td>300</td>
                          <td>600</td>
                        </tr>
                      </tbody>
                    </table>
                    </div>
                    <ol>
                      <li>Promotional period lasts from the 15th day, 12PM (GMT+8) – 18th, 12PM (GMT+8) of each month</li>
                    <li>Players are only allowed to claim the Payday Bonus once per day. The Payday Bonus amount will correspond to the players' respective VIP tier</li>
                    <li>There is a 1x rollover requirement before any withdrawal of the Payday Bonus can be done</li>
                    <li>SG8 reserves the right to amend the terms and conditions of the Payday Bonus without notice in the event there is reasonable suspicion of risk of fraud, and/or any other factors deemed relevant</li>
                    <li>SG8 reserves the right to take back any bonuses claimed by players in the event that the terms and conditions of the Payday Bonus are found to have been breached by the player, and/or there is reasonable suspicion of foul play</li>
                    </ol>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        </div>
      </>
    );
  }
  
  export default Vip;