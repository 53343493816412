import React, { useState, useEffect } from "react";
import LoadingSpinner from "../../snippets/LoadingSpinner";
import BackButton from "../../snippets/BackButton";
import PromoBanner from "../../snippets/PromoBanner";
import { Helmet } from "react-helmet";

export default function PromotionPage() {
    const title = "Deposit Bonus";
    const [loading, setLoading] = useState(true);
    useEffect(() => {
      setTimeout(() => {
        setLoading(false);
      }, 70);
    }, []);
  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Deposit Bonus - Discover and Enjoy Online Casino Bonus at SG8 Philippines</title> <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
    </Helmet>
    <div className="promotionPage">
    <BackButton name={title}/>
    {loading ? (
        <LoadingSpinner />
      ) : (
        <PromoBanner name={title}/>
    )}
        <h1>Deposit Bonus, Up to 150% bonus on 1st to 5th Deposits, <span className="highlight">Max of Php 3,888</span></h1>
      
        <div className="table-main">
          <table>
            <thead>
                <th>Deposit Count</th>
                <th>Deposit Amount</th>
            </thead>
            <tbody>
                <tr>
                  <td>1st Deposit</td>
                  <td>150% of Deposit</td>
                </tr>
                <tr>
                  <td>2nd Deposit</td>
                  <td>25% of Deposit</td>
                </tr>
                <tr>
                  <td>3rd Deposit</td>
                  <td>50% of Deposit</td>
                </tr>
                <tr>
                  <td>4th Deposit</td>
                  <td>75% of Deposit</td>
                </tr>
                <tr>
                  <td>5th Deposit</td>
                  <td>150% of Deposit</td>
                </tr>
            </tbody>
          </table>
        </div>
        <h2>General Mechanics:</h2>
        <ol>
          <li>The player must be a verified player of SG8.Casino.</li>
          <li>Bonus Limit and Turnover Requirement before any withdrawals.</li>
        </ol>
        <div className="table-main">
          <table>
            <thead>
                <th>Deposit Bonus</th>
                <th>Bonus Limit</th>
                <th>Turnover Requirements</th>
            </thead>
            <tbody>
                <tr>
                  <td>150% of Deposit</td>
                  <td>Php 3,888</td>
                  <td>15x</td>
                </tr>
                <tr>
                  <td>25% of Deposit</td>
                  <td>Php 3,888</td>
                  <td>3x</td>
                </tr>
                <tr>
                  <td>50% of Deposit</td>
                  <td>Php 3,888</td>
                  <td>5x</td>
                </tr>
                <tr>
                  <td>75% of Deposit</td>
                  <td>Php 3,888</td>
                  <td>8x</td>
                </tr>
                <tr>
                  <td>100% of Deposit</td>
                  <td>Php 3,888</td>
                  <td>10x</td>
                </tr>
            </tbody>
          </table>
        </div>
        <ol>
            <li>Bonus Computation and Turnover requirements example:
              <p><strong>First Depost:</strong> Php 1,000</p>
              <p><strong>Bonus:</strong>Php 1,000 x 150% = Php 2,500</p>
              <p><strong>Turnover Requirements:</strong>(Php 1,000 + Php 1,500) x 15 = Php 52,500</p>
            </li>
            <li>The turnover requirement must be met within 30 days upon awarding and activation of the reward to be eligible. Otherwise, the same shall be cancelled.</li>
            <li>In order to claim the bonus, before deposit, the player must select the option “Bonus” in the Bonus Tab, after successful deposit, the bonus will be automatically credited to the account.</li>
            <li>This promotion is limited to a single redemption per player.</li>
            <li>This promo cannot be used in conjunction with any other active deposit bonus promos at the same time, if there’s any.</li>
        </ol>
        <h2>Terms and conditions:</h2>
        <ol>
          <li>All prizes and other marketing costs shall be shouldered by the Service Provider – SG8.</li>
          <li>General SG8.Casino terms and conditions apply.</li>
          <li>SG8.Casino reserves the right to take back any bonuses claimed by players in the event that the terms and conditions of the promotion are found to have been breached by the player, and/or there is reasonable suspicion of foul play.</li>
          <li>SG8.Casino reserves the right to modify or cancel the promotion at any time without prior notice and subject for PAGCOR’s approval.</li>
          <li>In case of any irregularities or disputes, SG8.Casino’s decision will be final. Any issues or concerns should be raised with the customer support team, and they will be resolved promptly and fairly.</li>
        </ol>
    </div>
  </>;
}
