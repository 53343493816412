import React from 'react';

const SlotSearchList = ({ searchQuery, setSearchQuery }) => {
    return (
        <div className="slot-search-box">
            <input className="searchBox" placeholder="🔎︎ Search" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}/>
        </div>
    );
};

export default SlotSearchList;
