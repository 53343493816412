import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const tokenURL = "https://sg8.casino/json/wlp/sg8/token.jsp";
const wlpLaunchURL = "https://sg8.casino/json/wlp/launch.ig";
const apiJSONURL = "https://sg8.casino/json/wlp/json/index.jsp";

const GameList = ({ provider, filter, searchQuery }) => {
    const [games, setGames] = useState([]);
    const [loading, setLoading] = useState(true);
    const [launching, setLaunching] = useState(false);
    const [currentFrame, setCurrentFrame] = useState(19);
    const [animationComplete, setAnimationComplete] = useState(false);
    const totalFrames = 77; // The last frame number

    const imageRef = useRef(null);
    const isMobile = window.innerWidth <= 840;

    useEffect(() => {
        const fetchGames = async () => {
            try {
                const domain = isMobile ? "&m=1" : "";
                const response = await axios.post(apiJSONURL, `cmd=getGame&p=${provider}${domain}&domain=sg8`, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                });

                const jsonData = response.data; 
                const loadedGames = Object.values(jsonData).map(data => ({
                    gameId: data[0],
                    gameName: data[1],
                    gameImg: data[2],
                    gameDemo: data[3],
                    gameCategory: data[4],
                    gameTab: data[5],
                }));
                setGames(loadedGames);
                setLoading(false);
            } catch (error) {
                console.error("Failed to fetch games:", error);
                setLoading(false);
            }
        };

        fetchGames();
    }, [provider, isMobile]);

    useEffect(() => {
        let interval;
        if (loading || launching) {
            interval = setInterval(() => {
                setCurrentFrame(prevFrame => {
                    if (prevFrame < totalFrames) {
                        return prevFrame + 1;
                    } else {
                        setAnimationComplete(true);
                        clearInterval(interval);
                        return totalFrames;
                    }
                });
            }, 30); // Adjust the interval to 30 milliseconds for 2x speed
        }
        return () => clearInterval(interval);
    }, [loading, launching]);

    const launchGame = async (gameId) => {
        setLaunching(true);
        setCurrentFrame(19); // Reset the frame
        setAnimationComplete(false); // Reset animation complete
        if (imageRef.current) {
            imageRef.current.classList.remove('pop');
        }

        try {
            const username = localStorage.getItem('username');
            const encryptedUsernameResponse = await axios.post(apiJSONURL, `cmd=getToken&u=${username}`);
            const encryptedUsername = encryptedUsernameResponse.data.token;

            const tokenResponse = await axios.get(`${tokenURL}?u=${encryptedUsername}&g=${gameId}&t=transfer&m=mobile`);
            const sessionToken = tokenResponse.data.token;

            const lang = "en";
            const mobileIndicator = isMobile ? "true" : "false";
            const url = `${wlpLaunchURL}?s=${sessionToken}&l=${lang}&mobile=${mobileIndicator}`;
            console.log("Launching game with URL:", url);

            const launchGameAfterAnimation = () => {
                const popupWindow = window.open(
                    url,
                    '_blank',
                    'toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=800, height=600'
                );

                if (popupWindow) {
                    popupWindow.focus();
                } else {
                    alert('Please disable your pop-up blocker and try again.');
                }

                setLaunching(false);
            };

            if (animationComplete) {
                launchGameAfterAnimation();
            } else {
                setTimeout(() => {
                    launchGameAfterAnimation();
                }, (totalFrames - currentFrame) * 30); // Remaining animation time
            }
        } catch (error) {
            console.error("Failed to launch game:", error);
            setLaunching(false);
        }
    };

    const filterGames = () => {
        return games.filter(game => {
            let matchesFilter = true;
            if (filter) {
                switch (filter) {
                    case 'featured':
                        matchesFilter = game.gameTab.includes("featured");
                        break;
                    case 'slots':
                        matchesFilter = game.gameCategory === 1;
                        break;
                    case 'table':
                        matchesFilter = game.gameCategory === 2;
                        break;
                    case 'videopoker':
                        matchesFilter = game.gameCategory === 5;
                        break;
                    case 'arcade':
                        matchesFilter = game.gameCategory === 4;
                        break;
                    case 'jackpotslots':
                        matchesFilter = game.gameCategory === 6;
                        break;
                    default:
                        matchesFilter = true;
                        break;
                }
            }

            if (searchQuery) {
                matchesFilter = matchesFilter && game.gameName.toLowerCase().includes(searchQuery.toLowerCase());
            }

            return matchesFilter;
        }).sort((a, b) => a.gameName.localeCompare(b.gameName));
    };

    if (loading) {
        return (
            <section className='loadingGames'>
                <img src="/images/sg8.gif" style={{ width: '200px' }} alt="Loading..." />
            </section>
        );
    }

    const handleImageLoad = (event) => {
        event.target.style.opacity = 1;
    };

    const filteredGames = filterGames();

    return (
        <div className='gameListWrapper'>
            {launching && (
                <div className='overlay'>
                    <div className='loadingBar'>
                        <div className={`spinner ${animationComplete ? 'pop' : ''}`}>
                            <img ref={imageRef} src={`/images/frameLoading/sg8logo_${String(currentFrame).padStart(5, '0')}.png`} alt='Loading...' />
                        </div>
                    </div>
                </div>
            )}
            {filteredGames.length > 0 ? (
                filteredGames.map((game, index) => (
                    <div key={index} className="game" onClick={() => isMobile ? launchGame(game.gameId) : null}>
                        <div className="gameImg">
                            <img alt={`${game.gameName} ${provider.toUpperCase()}`} src={game.gameImg} onLoad={handleImageLoad} style={{ opacity: 0, transition: 'opacity 0.5s' }} />
                        </div>
                        {!isMobile && (
                            <div className='gameDetails'>
                                <div className="gameName">{game.gameName}</div>
                                <button onClick={() => launchGame(game.gameId)}>Play Now</button>
                            </div>
                        )}
                    </div>
                ))
            ) : (
                <section className='loadingGames'>
                    <div>
                        <img src="/images/sg8-icon.webp" style={{ width: '60px' }} />
                        <p>No games found for selected filter.</p>
                    </div>
                </section>
            )}
        </div>
    );
};

export default GameList;
