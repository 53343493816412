import React from "react";
import { promotionList } from "../pages/Promotion";

export default function PromoBanner(imgProps) {

const promotionItem = promotionList.find(item => item.title === imgProps.name);
  return <>
  <div className="promotionBanner">
      <img src={promotionItem.imgLarge}/>
    </div>
    <div className="promotionBannerMobile">
      <img src={promotionItem.imgSmall}/>
    </div>
 </>;
}
