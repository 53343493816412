import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const settings = {
  dots: true,
  infinite: true,
  speed: 200,
  fade: true,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const MainSlider = ({ sliderCurrentPage, bannerCurrentPage }) => {
  const slides = [
    { id: 'RTG', src: '/images/slots/hero/realtime-gaming-hero.webp', alt: 'RealTime Gaming', description: 'Play Asia top online slot games on Spadegaming.', buttonText: 'Why Spadegaming?', href: '/slots/rtg', position: 'right' },
    { id: 'SPADEGAMING', src: '/images/spadegaming-hero.webp', alt: 'Spadegaming', description: 'Play Asia top online slot games on Spadegaming.', buttonText: 'Why Spadegaming?', href: '/slots/rtg', position: 'right' },
    { id: 'PLAYSTAR', src: '/images/playstar-hero.webp', alt: 'Playstar', description: 'Play Asia top online slot games on Spadegaming.', buttonText: 'Why Spadegaming?', href: '/slots/playstar', position: 'left' },
  ];

  const activeSlideIndex = slides.findIndex(slide => slide.id === bannerCurrentPage);

  // If there is an active slide, move it to the first position
  if (activeSlideIndex > 0) {
    const [activeSlide] = slides.splice(activeSlideIndex, 1);
    slides.unshift(activeSlide);
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 200,
    fade: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 800, // Mobile view
        settings: {
          slidesToShow: 1, // Show 1.1 slides to slightly show the second slide
          centerMode: true,
          fade: true,
          centerPadding: '10px', // Adjust padding to control the visibility of the second slide
        },
      },
    ],
  };

  const renderSlide = (slide, index) => {
    const Tag = index === 0 ? 'h1' : 'h2'; // Use <h1> for the first slide (active slide), <h2> for others
    return (
      <div key={slide.id} className='slide-container'>
        <div className={`hero-banner-wrapper heroBannerWrapper-${slide.position}`} style={{ color: '#fff' }}>
          <Tag className={`headingTag ${index === 0 ? 'active-slide' : ''}`}>{slide.alt}</Tag>
          <p className='slide-description'>{slide.description}</p>
          <a href={slide.href} className='heroBtn'>{slide.buttonText}</a>
        </div>
        <img src={slide.src} alt={slide.alt} className='slide-image' />
      </div>
    );
  };

  return (
    <div className='sg8-hero-banner'>
      <Slider {...settings}>
        {slides.map((slide, index) => renderSlide(slide, index))}
      </Slider>
    </div>
  );
};

export default MainSlider;
