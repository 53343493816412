import React from 'react'

export default function MobileNavigation() {
  const navItems = [
    { id: 1, img: "/images/slot-casino-mobile-icon.webp", alt: "", span: "Casino", navp: "Slot", href: '/slots/rtg'},
    { id: 2, img: "/images/live-dealer-mobile-icon.webp", alt: "", span: "Dealer", navp: "Live", href: '/live-casino/'},
    { id: 3, img: "/images/sports-book-mobile-icon.webp", alt: "", span: "Book", navp: "Sports", href: '/sportsbook/'},
    { id: 4, img: "/images/promo-mobile-icon.webp", alt: "", span: "Deal", navp: "Promo", href: '/promotion/'},
    { id: 5, img: "/images/recent-games-mobile-icon.webp", alt: "", span: "Games", navp: "Recent"},
  ]
  const listItems = navItems.map((data) => (
    <li key={data.id}>
      <a href={data.href} className='mobileNav'>
        <img src={data.img} alt={data.img}/>
        <p>{data.navp}</p>
        <p className='navSpan'>{data.span}</p>
      </a>
    </li>
  ));
  return (
    <>
        <section className='mobileFooterNav'>
            <ul>
                {listItems}
            </ul>
        </section>
    </>
  )
}
