import React, { useState } from 'react';
import axios from 'axios';
import qs from 'qs';

function Withdraw() {

    const GcashBtn = <div id="GcashBtn"><img src="/images/gcash-icon.webp" alt="QRPH"/>Gcash</div>;
    const eWalletBtn = <div id="eWalletBtn"><img src="/images/ewallet-icon.webp" alt="PayMaya"/>eWallet</div>;
    const BankBtn = <div id="FortunePayBtn"><img src="/images/bank-icon.webp" alt="FortunePay"/>Bank Account</div>;

    const [visibleId, setVisibleId] = useState('withdrawalFormGcash'); // State to track which element should be visible
  
    // Function to handle click event on deposit links
    const handleWithdrawClick = (id) => {
      setVisibleId(id); // Update the state to show the corresponding element
    };

    const [amt, setAmount] = useState('');
    const [mac, setMac] = useState('');
    const [alertMessage, setAlertMessage] = useState('');

    const handleGcashSubmit = async () => {
      const username = localStorage.getItem('username');
      const amount = parseFloat(amt);
      const accountno = parseFloat(mac);
      try {
        const response = await axios.post('https://sg8.casino/json/wlp/json/index.jsp', 
          qs.stringify({
            cmd: 'WithdrawRequestPayCools',
            mbc: 'EWALLET',
            man: 'GCASH',
            mac: accountno,
            u: username,
            amt: amount,
          }), {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          });
        console.log('API response:', response);
        if (response.data && response.data.url) {
          window.location.href = response.data.url;
        } else {
          setAlertMessage('An unexpected error occurred. Please contact support for assistance.');
        }
      } catch (error) {
        console.error('API call error:', error);
        setAlertMessage('An unexpected error occurred. Please contact support for assistance.');
      }
    };

    return (
      <>
        <div className="account-container">
          <div className="page-headlines-container">
            <div className="page-h2-text-style">
              <h2>Withdrawal Request</h2><span>Get our Best Deals from SG8.Casino</span>
            </div>
          </div>
          <p className="remainingrollover">Remaining Rollover: <span id="remainingRollover-amt">...</span></p>
        <div className='withdraw-container'>
          <div className="withdraw-nav nav-tabs">
            <a className={`${visibleId === 'withdrawalFormGcash' ? 'active' : ''}`} onClick={() => handleWithdrawClick('withdrawalFormGcash')}>{GcashBtn}</a>
            <a className={`${visibleId === 'withdrawalFormEWallet' ? 'active' : ''}`} onClick={() => handleWithdrawClick('withdrawalFormEWallet')}>{eWalletBtn}</a>
            <a className={`${visibleId === 'withdrawalFormBank' ? 'active' : ''}`} onClick={() => handleWithdrawClick('withdrawalFormBank')}>{BankBtn}</a>
          </div>
          <div className='all-form-container'>
          {visibleId === 'withdrawalFormBank' && <form id="withdrawalFormHelp2Pay">
            <div className="form-title">{BankBtn}</div>
            <input name="cmd" type="hidden" value="WithdrawRequestHelp2Pay" /><input name="u" type="hidden" value="" id="withdrawal-help2pay-username" />
              <div className="row">
                  <div className="hLabel">Bank</div>
                    <div className="input-container"><select name="mbc" className="form-control">
                    <option value="">Select Bank</option>
                    <option value="GCASH">GCASH (GCASH)</option>
                    <option value="BDO">Banco de Oro (BDO)</option>
                    <option value="BDOE">Banco de Oro Corporate(BDOE)</option>
                    <option value="BPI">Bank of the Philippine Islands (BPI)</option>
                    <option value="LBP">Land Bank of the Philippines (LBP)</option>
                    <option value="MTB">MetroBank (MTB)</option>
                    <option value="PNB">Philippine National Bank (PNB)</option>
                    <option value="PNBe">Philippine National Bank Biz (PNBe)</option>
                    <option value="RCBC">Rizal Commercial Banking Corporation (RCBC)</option>
                    <option value="CBC">Chinabank (CBC)</option>
                    <option value="CBCe">Chinabank Biz (CBCe)</option>
                    <option value="SBC">Security Bank Corporation (SBC)</option>
                    <option value="SBCe">Security Bank Biz (SBCe)</option>
                    <option value="DBP">Development Bank of the Philippines (DBP)</option>
                    <option value="SLBA">Sterling Bank Asia (SLBA)</option>
                    <option value="EWB">Eastwest Bank (EWB)</option>
                    <option value="UBP">Union Bank of Philippines (UBP)</option>
                    <option value="SEABANK">SEABANK</option>
                    <option value="DCPAY">COINS.PH (DC PAY)</option>
                    <option value="MAYA">Maya Bank</option>
                    <option value="PAYMAYA">PayMaya Philippines</option>
                    <option value="RBC">Robinsons Bank Corporation</option>
                    <option value="AUB">Asia United Bank</option>
                    <option value="GRABPAY">GRABPAY</option>
                    <option value="GOTYME">GOTYME</option>
                    <option value="PSB">Philippine Savings Bank</option>
                    <option value="CIMBP">CIMB PH</option>
                  </select>
                  </div>
              </div>
              <div className="row">
                  <div className="hLabel">Bank Account Name</div>
                  <div className="input-container"><input className="form-control" title="" autoComplete="off" name="man" type="text" value="" placeholder="Bank Account Name" data-trigger="focus" data-toggle="popover" data-placement="bottom" data-content="" data-original-title="Bank Account Name" /></div>
              </div>
              <div className="row">
                  <div className="hLabel">Bank Account Number</div>
                  <div className="input-container"><input className="form-control" title="" autoComplete="off" name="mac" type="text" value="" placeholder="Bank Account Number" data-trigger="focus" data-toggle="popover" data-placement="bottom" data-content="" data-original-title="Bank Account Number" /></div>
              </div>
              <div className="row">
                  <div className="hLabel">Balance</div>
                  <div className="input-container"><div id="withdrawal-help2pay-balance" className="balance">...</div></div>
              </div>
              <div className="row">
                  <div className="hLabel">Withdrawal Amount</div>
                  <div className="input-container"><input id="withdrawal-help2pay-amount" className="form-control" autoComplete="off" max="1000000" maxLength="10" min="100" name="amt" required="required" type="text" placeholder="Min:100, Max:1,000,000" /></div>
              </div>
              <div className="row">
                  <div className="input-container"  colSpan="2" style={{textAlign: "center", background: "transparent", borderBottom: "0px!important"}}>
                      <div id="withdrawal-help2pay-alert-cont" className="alert alert-info fade in alert-dismissable"><div id="withdrawal-help2pay-alert"></div></div>
                      <button id="btn-help2pay-submit" className="red-button" type="button">SUBMIT</button>
                  </div>
              </div>
              </form>}
            
          {visibleId === 'withdrawalFormGcash' && <form id="withdrawalFormGcash">
            <div className="form-title">{GcashBtn}</div>
            <input name="cmd" type="hidden" value="gcash" /><input name="mbc" type="hidden" value="gcash" /><input name="man" type="hidden" value="gcash" />
            <div className="row">
              <div className="hLabel">Phone Number</div>
              <div className="input-container"><input className="form-control" value={mac} onChange={(e) => setMac(e.target.value)} autoComplete="off" maxLength="11" required="required" type="text" placeholder="Phone Number (eg: 09123456789)" data-trigger="focus" data-toggle="popover" data-placement="bottom" data-content="Registered phone number at FortunePay starting with [0]" data-original-title="Phone Number" /></div>
            </div>
            <div className="row">
              <div className="hLabel">Balance</div>
              <div className="input-container"><div id="withdrawal-gcash-balance" className="balance">...</div></div>
            </div>
            <div className="row">
              <div className="hLabel">Withdrawal Amount</div>
              <input id="paycools-amount" className="form-control txt-amount" autoComplete="off" max="30000" maxLength="10" min="100" name="amt" required="required" type="text" placeholder="Min:100, Max:50,000" value={amt} onChange={(e) => setAmount(e.target.value)} />
            </div>
            <div className="row">
              <div className="input-container" colSpan="2" style={{textAlign: "center", background: "transparent", borderBottom: "0px!important"}}>
                <div className="alert alert-info fade in alert-dismissable">{alertMessage}</div>
                <button className="yellow-btn" type="button" onClick={handleGcashSubmit}>SUBMIT</button>
              </div>
            </div>
            </form>}

          {visibleId === 'withdrawalFormEWallet' && <form id="withdrawalFormPayCools">
            <div className="form-title">{eWalletBtn}</div>
            <input name="cmd" type="hidden" value="WithdrawRequestPayCools" /><input name="u" type="hidden" value="" id="withdrawal-paycools-username" />
              <div className="row">
                <div className="hLabel" id="payCoolsWalletLabel">eWallet</div>
                  <div className="input-container">
                    <select id="payCoolsWallet" name="man" className="form-control">
                      <option value="">Select eWallet</option>
                      <option value="COINS">COINS</option>
                      <option value="PAYMAYA">PAYMAYA</option>
                      <option value="GRABPAY">GRABPAY</option>
                      <option value="SHOPEE">SHOPEE</option>
                      <option value="OMNIPAY">OMNIPAY</option>
                    </select>
                  </div>
              </div>
              <div className="row">
                  <div className="hLabel">Mobile Number</div>
                  <div className="input-container"><input className="form-control" title="" autoComplete="off" name="mac" type="text" value="" placeholder="Mobile Number (eg: 09XXXXXXXXX)" data-trigger="focus" data-toggle="popover" data-placement="bottom" data-content="" data-original-title="Bank Account Number" /></div>
              </div>
              <div className="row">
                  <div className="hLabel">Balance</div>
                  <div className="input-container"><div id="withdrawal-paycools-balance" className="balance">...</div></div>
              </div>
              <div className="row">
                  <div className="hLabel">Withdrawal Amount</div>
                  <div className="input-container"><input id="withdrawal-paycools-amount" className="form-control" autoComplete="off" max="1000000" maxLength="10" min="100" name="amt" required="required" type="text" placeholder="Min:100, Max:50,000" /></div>
              </div>
              <div className="row">
                  <div className="input-container"  colSpan="2" style={{textAlign: "center", background: "transparent", borderBottom: "0px!important"}}>
                  <div id="withdrawal-paycools-alert-cont" className="alert alert-info fade in alert-dismissable"><div id="withdrawal-paycools-alert"></div></div>
                  <button id="btn-paycools-submit" className="red-button" type="button">SUBMIT</button>
              </div>
              </div>
              </form>}
            </div>
          </div>
        </div>
    </>
      
  )
}

export default Withdraw