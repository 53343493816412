import React, { useState } from 'react';
import { Helmet } from "react-helmet";

export const promotionList = [
  {
    id: 1,
    title: "Deposit Bonus",
    desc: "Up to 150% bonus on 1st to 5th Deposits",
    alt: "",
    imgSmall: "/images/depositbonus.jpg",
    imgLarge: "/images/deposit_bonus.jpg",
    promoClick: "depositbonus"
  },
  {
    id: 2,
    title: "Payday Bonus",
    desc: "Payday Bonus at SG8 Casino",
    alt: "",
    imgSmall: "/images/paydaybonus.jpg",
    imgLarge: "/images/payday.jpg",
    promoClick: "paydaybonus"
  },
  {
    id: 3,
    title: "Birthday Bonus",
    desc: "Come and Celebrate your Birthday",
    alt: "",
    imgSmall: "/images/birthday-bonus.jpg",
    imgLarge: "/images/birthday_bonus.jpg",
    promoClick: "birthdaybonus"
  },
  {
    id: 4,
    title: "Referral Bonus",
    desc: "Referral Up to 10%",
    alt: "",
    imgSmall: "/images/bonus-referral.jpg",
    imgLarge: "/images/referral_bonus.jpg",
    promoClick: "referralbonus"
  },
  {
    id: 5,
    title: "Cashback Bonus",
    desc: "Offer Cashback Up to 6%",
    alt: "",
    imgSmall: "/images/cash_backv1.jpg",
    imgLarge: "/images/cash_back.jpg",
    promoClick: "cashbackbonus"
  }
]

function Promotion() {
  const [visibleId, setVisibleId] = useState(null); // State to track which element should be visible

  const listItems = promotionList.map((data)=>(
    <li className='promoBox' key={data.id}>
       <img className='promotionBannerMobile' src={data.imgLarge} alt={data.alt} />
       <img className='promotionBanner' src={data.imgSmall} alt={data.alt} />
       <div className="promotionContent">
          <p className="promotion-title">{data.title}</p>
          <p className="promotion-info">{data.desc}</p>
          <div className="promolink-container">
               <a className="yellow-btn" href={`${data.promoClick}`}>T&C</a>
          </div>
       </div>
    </li>
  ))
  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
            <title>Discover and Enjoy Online Casino Bonus at SG8 Philippines</title> <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
      </Helmet>

      <div className="main-wrapper">
        <div className="main-content-area">
            <div className="page-headlines-container">
              <div className="page-h2-text-style">
                <h1>Promotions</h1> <span>Get our Best Deals from SG8.Casino</span>
              </div>
            </div>
            <div className="promotionContentarea">
                <ul className="promotionWrapper">{listItems}</ul>
            </div>
        </div>
      </div>
     
      <div className="main-content-wrapper">
        <div className="text-content-area">
            <h2 className="text-content-title">Who is SG8 Casino?</h2>
            <p>Explore the vibrant essence of Philippines’ online casino at SG8 Casino – your meticulously crafted online gaming haven designed for ardent enthusiasts. Embark on an exhilarating journey into the world of online entertainment, promising a fusion of thrills, wins, and boundless excitement to captivate every player’s senses.</p>
            <span className="text-content-sub-title">How to Register at SG8 Casino?</span>
            <p>At SG8 Casino, we revolutionize the online gaming landscape with a spectrum of meticulously curated options, catering to the discerning tastes of the Filipino gaming community. From captivating Online Slots to Live Casino extravaganzas and heart-racing Sports Betting, all fortified by PAGCOR’s seal of safety and unwavering reliability.</p>
            <span className="text-content-sub-title">How to Register at SG8 Casino?</span>
            <p>Step into a journey in our Live Casino, powered by Evolution Gaming. Encounter the captivating suspense of Live Baccarat, the strategic depth of Blackjack, the spinning excitement of Live Roulette, and the dynamic entertainment of Crazy Time.</p>
            <div className="payment-menthod-container">
              <img src="/images/gcash-maya.webp" alt="" style={{width: `unset`}}/>
              <img src="/images/dragonpay.webp" alt="" style={{width: `unset`}}/>
              <img src="/images/fortunepay.webp" alt="" style={{width: `unset`}}/>
              <img src="/images/lazada.webp" alt="" style={{width: `unset`}}/>
            </div>
            <div></div>
        </div>
      </div>
    </>
  )
}

export default Promotion