import React, { useState, useEffect } from "react";
import LoadingSpinner from "../../snippets/LoadingSpinner";
import BackButton from "../../snippets/BackButton";
import { Helmet } from "react-helmet";
import PromoBanner from "../../snippets/PromoBanner";

export default function BirthdayBonus() {
    const title = "Birthday Bonus";
    const [loading, setLoading] = useState(true);
    useEffect(() => {
      setTimeout(() => {
        setLoading(false);
      }, 70);
    }, []);
  return <>
      <Helmet>
          <meta charSet="utf-8" />
            <title>Birthday Bonus - Discover and Enjoy Online Casino Bonus at SG8 Philippines</title> <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
      </Helmet>
     <div className="promotionPage">
        <BackButton name={title}/>
    {loading ? (
        <LoadingSpinner />
      ) : (
        <PromoBanner name={title}/>
    )}
        <h1>Come and Celebrate your Birthday with us! <span className="highlight">Get 500 credits!</span></h1>
        <p>Php 500 playing credits for eligible players.</p>
        <h2>General Mechanics:</h2>
        <ol>
          <li>To be eligible for the Birthday Bonus at SG8.casino, newly and existing fully verified players who has made at least three deposits in the last month. For instance, if your birthday is in September, you’ll need to have made three deposits in August.</li>
          <li>The Birthday bonus comes with a wagering requirement of Php 500.00 / 1x, which must be fulfilled before any winnings can be withdrawn.</li>
          <li>The player must contact customer support and present its valid ID with his/her birthday.</li>
          <li>The Birthday bonus will be credited by SG8 Customer Service Representative to the player’s SG8 Account within 24 hours from presentation of its valid ID with Birth Date.</li>
        </ol>
        <h2>Terms and conditions:</h2>
        <ol>
            <li>General SG8.Casino terms and conditions apply.</li>
            <li>SG8.Casino reserves the right to take back any bonuses claimed by players in the event that the terms and conditions of the promotion are found to have been breached by the player, and/or there is reasonable suspicion of foul play.</li>
        </ol>
    </div>
  </>;
}
