import React, { useEffect, useState } from 'react';

function LogoutButton() {
  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('username');
    window.location.href = "/"; // Redirect to homepage after logout
  };

  const [currentActive, setCurrentActive] = useState(0);
  const [isBodyLoggedIn, setIsBodyLoggedIn] = useState(false);
  const duration = 3000;

  const newsItems = [
    { id: 1, content: 'Apple releases new iPhone with 5G capability.' },
    { id: 2, content: 'Facebook under fire for handling of user data.' },
    { id: 3, content: 'Tesla unveils new electric car model with longer range.' },
    { id: 4, content: 'Google introduces new artificial intelligence tools for businesses.' },
    { id: 5, content: 'Amazon announces plans to expand its drone delivery program.' },
  ];

  const totalNews = newsItems.length;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentActive(prevActive => (prevActive + 1) % totalNews);
    }, duration);

    return () => clearInterval(intervalId); 
  }, [totalNews, duration]);

  useEffect(() => {
    setIsBodyLoggedIn(document.body.classList.contains('isLoggedIn'));
  }, []);

  return (
    <div className="header-news-logout">
      <div className="news-container">
        <div className="news-heading">News:</div>
        {newsItems.map((item, index) => (
          <div key={item.id}>
            <a href="#" className={`news-single ${index === currentActive ? 'active' : ''}`}>{item.content}</a>
          </div>
        ))}
      </div>
      {isBodyLoggedIn && (
        <span className="logout-BTN">
          <a onClick={handleLogout}>
            <img src="/images/logout.webp" alt=""/>
            <p>LOGOUT</p>
          </a>
        </span>
      )}
    </div>
  );
}

export default LogoutButton;
