import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const App = () => {
  const tokenURL = "https://sg8.casino/json/wlp/sg8/token.jsp";
  const wlpLaunchURL = "https://sg8.casino/wlp/launch.ig";
  const httpsLaunchURL = "https://sg8.casino/json/wlp/launch.ig";
  const apiJSONURL = "https://sg8.casino/json/wlp/json/index.jsp";

  const [launching, setLaunching] = useState(false);
  const [currentFrame, setCurrentFrame] = useState(19);
  const [animationComplete, setAnimationComplete] = useState(false);
  const totalFrames = 77; // The last frame number

  const launchGame = async (url, gameId, isMobile = false, lang = 'en', transfer = 0, isHttps = true) => {
    let mobile = isMobile ? 1 : 0;
    let username = localStorage.getItem('username');
    let launchURL = url;

    setLaunching(true);
    setCurrentFrame(19); // Reset the frame
    setAnimationComplete(false); // Reset animation complete

    if (document.body.classList.contains("isLoggedIn")) {
      try {
        const tokenResponse = await axios.post(apiJSONURL, `cmd=getToken&u=${username}`);
        const tokenResult = tokenResponse.data;
        username = tokenResult.token;

        const gameTokenResponse = await axios.post(tokenURL, `u=${username}&g=${gameId}&t=${transfer}&m=${mobile}`);
        const gameTokenResult = gameTokenResponse.data;
        const sessionToken = gameTokenResult.token;

        if (gameTokenResult.e) {
          alert(gameTokenResult.emsg);
          setLaunching(false);
          return;
        }

        launchURL = isHttps && !gameId.startsWith("33")
          ? `${httpsLaunchURL}?s=${sessionToken}&l=${lang}`
          : `${wlpLaunchURL}?s=${sessionToken}&l=${lang}`;

        if (isMobile) {
          launchURL += `&m=1&t=1&h=${encodeURIComponent(window.location.protocol + "//" + window.location.hostname + window.location.pathname)}`;
        }
        if (gameId.startsWith("47")) {
          launchURL += "&I=0";
        }
        if (gameId.startsWith("58") && !isMobile) {
          launchURL += `&h=${encodeURIComponent(window.location.protocol + "//" + window.location.hostname + window.location.pathname)}`;
        }

        if (!gameId.startsWith("25") && !gameId.startsWith("62")) {
          handleRecentGamesCookie(gameId);
        }

        if (document.querySelector('.gameIframe')?.style.display === 'block') {
          document.querySelector('.gameIframe').src = "";
        }

        if (!launchURL) {
          alertMessage(lang);
        } else {
          handleGameLaunch(launchURL, isMobile, gameId, lang);
        }
        setLaunching(false);
      } catch (error) {
        console.error(error);
        setLaunching(false);
      }
    } else {
      if (!username && !launchURL) {
        alertMessage(lang);
      } else {
        handleGameLaunch(launchURL, isMobile, gameId, lang);
      }
    }
  };

  useEffect(() => {
    let interval;
    if (launching) {
      interval = setInterval(() => {
        setCurrentFrame(prevFrame => {
          if (prevFrame < totalFrames) {
            return prevFrame + 1;
          } else {
            setAnimationComplete(true);
            clearInterval(interval);
            return totalFrames;
          }
        });
      }, 25); // Adjust the interval to 30 milliseconds for 2x speed
    }
    return () => clearInterval(interval);
  }, [launching]);

  const alertMessage = (lang) => {
    const messages = {
      th: "กรุณาล็อคอิน เพื่อเข้าสู่หน้าเล่นของเรา",
      cn: "FUN模式不可用，请注册并登录玩游戏。",
      default: "Fun Mode not available, please register and login to play the game."
    };
    alert(messages[lang] || messages.default);
  };

  const alertPopupBlocked = (lang) => {
    const messages = {
      cn: "窗口被阻止！ 请允许弹出窗口来玩游戏。",
      default: "Window Blocked! Please allow popup to play the game."
    };
    alert(messages[lang] || messages.default);
  };

  const handleGameLaunch = (launchURL, isMobile, gameId, lang) => {
    if (!isMobile) {
      const win = window.open(launchURL, "popup", "width=1024,height=768");
      if (win == null) {
        alertPopupBlocked(lang);
      }
    } else {
      if (gameId.startsWith("62")) {
        const win = window.open(launchURL, "popup", "width=1024,height=768");
        if (win == null) {
          alertPopupBlocked(lang);
        }
      } else {
        window.top.location.href = launchURL;
      }
    }
  };

  const handleRecentGamesCookie = (gameId) => {
    const cookieName = "recentGames";
    let cookies = getCookie(cookieName);
    if (!cookies) {
      setCookie(cookieName, gameId, 30);
    } else {
      if (cookies.includes(gameId)) {
        cookies = cookies.replace(`,${gameId}`, "").replace(`${gameId},`, "");
        cookies = `${gameId},${cookies}`;
      } else {
        cookies = `${gameId},${cookies}`;
        const cookiesArray = cookies.split(",");
        if (cookiesArray.length > 5) {
          cookies = cookiesArray.slice(0, 5).join(",");
        }
      }
      setCookie(cookieName, cookies, 30);
    }
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
  };

  return (
    <div className="liveCasino">
      <Game
        imgSrc="/images/livedealer/live_dealer01.jpg"
        // imgSrc1="/images/livedealer/casino-woman-1.png"
        altText="live casino powered by evolution"
        gameId="25000"
        gameName="Evolution"
        launchGame={launchGame}
        launching={launching}
        currentFrame={currentFrame}
        animationComplete={animationComplete}
      />
      <Game
        imgSrc="/images/livedealer/live_dealer02.jpg"
        // imgSrc1="/images/livedealer/casino-woman-1.png"
        altText="live casino powered by SA Gaming"
        gameId="36001"
        gameName="SA Gaming"
        launchGame={launchGame}
        launching={launching}
        currentFrame={currentFrame}
        animationComplete={animationComplete}
      />
      <Game
        imgSrc="/images/livedealer/live_dealer03.jpg"
        // imgSrc1="/images/livedealer/casino-woman-1.png"
        altText="live casino powered by AFB"
        gameId="74001"
        gameName="AFB"
        launchGame={launchGame}
        launching={launching}
        currentFrame={currentFrame}
        animationComplete={animationComplete}
      />
    </div>
  );
};

const Game = ({ imgSrc, imgSrc1, altText, gameId, gameName, launchGame, launching, currentFrame, animationComplete }) => {
  const imageRef = useRef(null);

  return (
    <div className='liveCasinoTest'>
      {launching && (
        <div className='overlay'>
          <div className='loadingBar'>
            <div className={`spinner ${animationComplete ? 'pop' : ''}`}>
              <img ref={imageRef} src={`/images/frameLoading/sg8logo_${String(currentFrame).padStart(5, '0')}.png`} alt='Loading...' />
            </div>
          </div>
        </div>
      )}
      <div className='liveContainer'>
        {/* <img className='liveChar' src={imgSrc1} alt={altText} /> */}
        <img className='charMain' src={imgSrc} alt={altText} />
        <div className='liveCasinoBtn'>
          <div className="hidden-xs yellow-btn" onClick={() => launchGame('', gameId, false, 'en', 0)}>Play Now</div>
          <div className="visible-xs yellow-btn" onClick={() => launchGame('', gameId, true, 'en', 0)}>Play Now</div>
        </div>
      </div>
      
    </div>
  );
};

export default App;
