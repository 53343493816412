import React from "react";
import "../style/loading.css"
export default function LoadingSpinner() {
  return(
    <>
    <section className="loader">
        <div>
            <img src="/images/sg8-icon.webp" alt="" />
            <div className="text">Please wait...</div>
        </div>
    </section>
  </>
  ) 
}
