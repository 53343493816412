import React, { useState, useEffect } from "react";
import LoadingSpinner from "../../snippets/LoadingSpinner";
import BackButton from "../../snippets/BackButton";
import { Helmet } from "react-helmet";
import PromoBanner from "../../snippets/PromoBanner";

export default function CashBackBonus() {
    const title = "Cashback Bonus";
    const [loading, setLoading] = useState(true);
    useEffect(() => {
      setTimeout(() => {
        setLoading(false);
      }, 70);
    }, []);

  return <>
      <Helmet>
          <meta charSet="utf-8" />
            <title>Cash Back Bonus - Discover and Enjoy Online Casino Bonus at SG8 Philippines</title> <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
      </Helmet>
     <div className="promotionPage">
        <BackButton name={title}/>
    {loading ? (
        <LoadingSpinner />
      ) : (
       <PromoBanner name={title}/>
    )}
        <h1>Offer Cashback Up to 6%</h1>
        <div className="table-main">
          <table>
            <thead>
                <th>VIP Tier</th>
                <th>Turnover Requirement</th>
                <th>Bonus</th>
            </thead>
            <tbody>
                <tr>
                  <td>Bronze</td>
                  <td>5x</td>
                  <td>3%</td>
                </tr>
                <tr>
                  <td>Silver</td>
                  <td>5x</td>
                  <td>4%</td>
                </tr>
                <tr>
                  <td>Gold</td>
                  <td>5x</td>
                  <td>5%</td>
                </tr>
                <tr>
                  <td>Platinum</td>
                  <td>5x</td>
                  <td>6%</td>
                </tr>
            </tbody>
          </table>
        </div>
        <h2>General Mechanics:</h2>
        <ol>
          <li>Open to all SG8 Players.</li>
          <li>Promotion period Monday 06:00 AM to next Monday 05:59 AM. A minimum net loss of PHP 4,000 during the 7-day period qualifies for a cashback (Note: Net Loss = Gross Bet Amount – Gross Payout).</li>
          <li>Players will receive up to 6% bonus based on their VIP tier and net losses within the promotion period.</li>
          <li>No Maximum cashback to be paid out.</li>
          <li>5x turnover is required before withdrawal of bonus.</li>
          <li>Crediting of bonus will be automatic, every Monday 3pm latest.</li>
        </ol>
        <h2>Terms and conditions:</h2>
        <ol>
            <li>All prizes and other marketing costs shall be shouldered by the Service Provider – SG8</li>
            <li>General SG8.Casino terms and conditions apply.</li>
            <li>SG8.Casino reserves the right to take back any bonuses claimed by players in the event that the terms and conditions of the promotion are found to have been breached by the player, and/or there is reasonable suspicion of foul play.</li>
            <li>SG8.Casino reserves the right to modify or cancel the promotion at any time without prior notice and subject for PAGCOR’s approval.</li>
            <li>In case of any irregularities or disputes, SG8.Casino’s decision will be final. Any issues or concerns should be raised with the customer support team, and they will be resolved promptly and fairly.</li>
        </ol>
    </div>
  </>;
}
