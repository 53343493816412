import React from 'react';
import { Helmet } from 'react-helmet';
import SBLaunch from '../snippets/SBLaunch';

function Sports() {
  const gameId = '62001'; // Default game ID from your HTML

  const openGameInNewWindow = () => {
    const launchURL = document.querySelector('.gameIframe')?.src;
    if (launchURL) {
      window.open(
        launchURL,
        'GameWindow',
        'width=800,height=600,resizable=yes,scrollbars=yes,status=yes'
      );
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Pinnacle Sportsbook | Bet on your favorite teams now! - SG8 Casino</title>
        <link rel="icon" href="/images/sg8-icon.webp" sizes="32x32" />
      </Helmet>
      <div className="main-wrapper">
        <div className="main-content-area">
          <div className="page-headlines-container">
            <div className="pageHeaderText">
              <h1 id="pinnaclesports">SG8 Sportsbook</h1>
              <p>Bet on your favorite team now!</p>
              <SBLaunch onOpenNewWindow={openGameInNewWindow} showIframe={false} />
            </div>
          </div>
          <div className="sportsbook-game-selector">
            <SBLaunch gameId={gameId} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Sports;
