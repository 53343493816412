import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faUser } from '@fortawesome/free-solid-svg-icons';
import Popup from 'reactjs-popup';
import LoginModal from './Modal/LoginModal';
import Register from './api/Register';

const apiJSONURL = "https://sg8.casino/json/wlp/json/index.jsp";

function Header() {
    const [showLoginForm, setShowLoginForm] = useState(false);
    const [showRegisterForm, setShowRegisterForm] = useState(false);
    const [userData, setUserData] = useState({ userId: '', balance: null });
    const [isBodyLoggedIn, setIsBodyLoggedIn] = useState(false);

    useEffect(() => {
        const username = localStorage.getItem('username');
        if (username) {
            const fetchUserData = async () => {
                try {
                    const response = await fetch(`${apiJSONURL}?cmd=getBalance&u=${username}`);
                    const data = await response.json();

                    // Assuming the API response contains the balance property
                    const balance = data.balance;

                    setUserData({ userId: username, balance });
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            };

            fetchUserData();
        }
    }, []);

    const handleRegisterFromLoginClick = () => {
        setShowLoginForm(false);
        setShowRegisterForm(true);
    };
    
    const handleLoginFromRegisterClick = () => {
        setShowRegisterForm(false);
        setShowLoginForm(true);
    };

    const handleLoginClick = () => {
        setShowLoginForm(true);
    };

    const handleRegisterClick = () => {
        setShowRegisterForm(true);
    };

    const handleCloseClick = () => {
        setShowLoginForm(false);
        setShowRegisterForm(false);
    };

    useEffect(() => {
        setIsBodyLoggedIn(document.body.classList.contains('isLoggedIn'));
    }, []);

    return (
        <>
            <div id="LoginTrigger" className={`modal ${showLoginForm ? 'show' : ''}`}>
                <div className='modal-main-container' style={{ backgroundImage: `url("/images/registration-form-bg.jpg")` }}>
                    <LoginModal />
                    <button className='modal-close' onClick={handleCloseClick}>✖</button>
                    {!showRegisterForm && <div className='HaveAccount'>Don't have an account? <a onClick={handleRegisterFromLoginClick}>Register</a></div>}
                </div>
            </div>
            <div id="RegTrigger" className={`modal ${showRegisterForm ? 'show' : ''}`}>
                <div className='modal-main-container' style={{ backgroundImage: `url("/images/registration-form-bg.jpg")` }}>
                    <Register />
                    <button className='modal-close' onClick={handleCloseClick}>✖</button>
                    {!showLoginForm && <div className='HaveAccount'>Have an account? <a className='login' onClick={handleLoginFromRegisterClick}>Login</a></div>}
                </div>
            </div>
            <header id="masthead" className="site-header has-logo has-menu">
                <div className="sticky-navigation desktop-view">
                    <div className="navigation-fixed-container">
                        <a href="/"><img className="website-logo" src="/images/sg8-casino-logo-1.webp" alt="official sg8 logo" /></a>
                        <div className="user-information">
                            <div className="userwrapper">
                                <span className="userID">{userData.userId}</span>
                                <span className="userBalance">{userData.balance !== null ? userData.balance : 'Loading...'}</span>
                            </div>
                            <div className="c2a-depo-withdraw">
                                <a href="/account/deposit/" className="depo-btn">Deposit</a>
                                <a href="/account/withdraw/" className="withdraw-btn">Withdraw</a>
                            </div>
                        </div>
                        <div className="c2a-landingpage">
                            <a onClick={handleLoginClick} className="login-btn">Login</a>
                            <a onClick={handleRegisterClick} className="reg-btn">Register</a>
                        </div>
                        <div className="navigation-menu-list">
                            <div className="primary-menu-list">
                                <div className="menu" style={{ backgroundImage: `url(/images/slots-navigation.webp)` }}><a href="/slots/rtg/"><span>Slots</span></a></div>
                                <div className="menu" style={{ backgroundImage: `url(/images/ld-navigation.webp)` }}><a href="/live-casino/"><span>Live Dealer</span></a></div>
                                <div className="menu" style={{ backgroundImage: `url(/images/sportsbook-navigation.webp)` }}><a href="/sportsbook/"><span>Sportsbook</span></a></div>
                            </div>
                            <div className="secondary-menu-list">
                                <ul>
                                    <li><a href="/promotion/">Promotion</a></li>
                                    <li><a href="/vip/">VIP</a></li>
                                    <li><a href="/account/dashboard/">Account</a></li>
                                </ul>
                            </div>
                            <div className="navarea-promotions">
                                <img src="/images/depositbonus-with-ring.webp" alt="" />
                                <a href="#"><span>FULL T&C</span></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mobile-header-container mobile-view">
                    <section>
                        <div className="mobile-website-logo">
                            <a href="/">
                                <img className="website-logo" src="/images/sg8-casino-logo-1.webp" alt="official sg8 logo" />
                            </a>
                        </div>
                        {!isBodyLoggedIn && (
                            <div className='mobile-login-reg'>
                                <a onClick={handleLoginClick} className="login-btn">Login</a>
                            </div>
                        )}
                        {isBodyLoggedIn && (
                            <div className='mobile-balance'>
                                <div>
                                    <p>Balance</p>
                                    <span className='balance'>{userData.balance !== null ? userData.balance : 'Loading...'}</span>
                                </div>
                                <div style={{ color: "white" }} className="tooltipBoundary">
                                    <Popup
                                        trigger={
                                            <button style={{background: "none"}} type="button" className="button">
                                                <FontAwesomeIcon style={{ color: '#111', fontSize: '20px', padding: "5px" }} icon={faChevronDown} />
                                            </button>
                                        } 
                                        position="top center"
                                        closeOnDocumentClick
                                    >
                                        <p><a href='/account/deposit/'>Deposit</a></p>
                                        <p><a href='/account/withdraw/'>Wallet</a></p>
                                    </Popup>
                                </div>
                                <div>
                                    <a href="/">
                                        <FontAwesomeIcon  style={{ color: '#fff', fontSize: '20px',  padding: "5px" }} icon={faUser} />
                                    </a>
                                </div>
                            </div>
                        )}
                    </section>
                </div>
            </header>
        </>
    );
}

export default Header;
